import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import CodeTracks from "../pages/codetracks/index.js";
import Home from "../pages/Home.js";
import Dashboard from "../pages/management/index.js";
import AdminCodetracks from "../pages/management/codetracks.js";
import AdminTrack from "../pages/management/AdminTrack.js";
import AdminTrackModules from "../pages/management/AdminTrackModules.js";

import BuiltinTrack from "../pages/codetracks/BuiltinTrack.js";
import TrackProblemIDE from "../pages/codetracks/TrackProblemIDE.js";

function Navbar() {
  return (
    <>
      <Router>
        <nav className="font-sans flex flex-col text-center sm:flex-row sm:text-left sm:justify-between py-4 px-6 bg-white shadow sm:items-baseline w-full">
          <div className="mb-2 sm:mb-0">
            <Link
              to="/home"
              className="text-2xl no-underline text-grey-darkest hover:text-blue-dark"
            >
              Home
            </Link>
          </div>
          <div>
            <Link
              to="/codetracks"
              className="text-lg no-underline text-grey-darkest hover:text-blue-dark ml-2"
            >
              CodeTracks
            </Link>
            <Link
              to="/dashboard"
              className="text-lg no-underline text-grey-darkest hover:text-blue-dark ml-2"
            >
              Managment Dash
            </Link>
          </div>
        </nav>

        <Routes>
          <Route path="/codetracks" element={<CodeTracks />} />
          <Route path="/home" element={<Home />} />
          <Route
            path="/codetracks/builtin-methods"
            element={<BuiltinTrack />}
          />
          <Route
            path="/codetracks/builtin-methods/:problemId"
            element={<TrackProblemIDE />}
          />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/dashboard/codetracks" element={<AdminCodetracks />} />
          <Route
            path="/dashboard/codetracks/choice/:choice"
            element={<AdminTrack />}
          />
          <Route
            path="/dashboard/codetracks/choice/:choice/:problem_title"
            element={<AdminTrackModules />}
          />
        </Routes>
      </Router>
    </>
  );
}

export default Navbar;
