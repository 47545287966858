import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import BuiltinTrack from "./BuiltinTrack.js";
function CodeTracks() {
  return (
    <>
      <h2 className="text-center">CodeTracks</h2>
      <div className="h-screen flex justify-center mt-4">
        <div className="w-2/3">
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
            <div className="h-32 max-w-full rounded-lg bg-blue-200 text-center pt-4">
              <Link
                to="/codetracks/builtin-methods"
                className="text-2xl no-underline text-grey-darkest hover:text-blue-dark"
              >
                Builtin Methods
              </Link>
            </div>

            <div className="h-32 max-w-full rounded-lg bg-blue-200 text-center pt-4">
              <h2>Beginner</h2>
            </div>
            <div className="h-32 max-w-full rounded-lg bg-blue-200 text-center pt-4">
              <h2>Intermediate</h2>
            </div>
            <div className="h-32 max-w-full rounded-lg bg-blue-200 text-center pt-4">
              <h2>Advanced</h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default CodeTracks;
