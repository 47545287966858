import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { BsPlusSquare } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { FcExpand } from 'react-icons/fc';
import {MdDelete} from "react-icons/md";
import Modal from 'react-modal';

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    backgroundColor       : '#f3f3f3',
    borderRadius          : '10px',
    maxWidth              : '300px',  // Change this for your needs
    maxHeight             : '200px',  // Change this for your needs
    overflow              : 'auto',
    padding               : '20px'
  }
};

Modal.setAppElement("#root"); // #root or whatever id you have on your root div

const AdminTrack = function () {
  const navigate = useNavigate();
  const location = useLocation();
  const { title } = location.state;
  const [trackData, setTrackData] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [newModuleValue, setNewModuleValue] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [moduleToBeDeleted, setModuleToBeDeleted] = useState(null);

  const navigateToProblemDetail = function (module_title, module) {
    navigate(`/dashboard/codetracks/choice/${title}/${module_title}`, {
      state: { module_title: module_title, track_name: title, module: module },
    });
  };

  const handleInputChange = (event) => {
    setNewModuleValue(event.target.value);
  };

  const handleModuleDelete = () => {
    axios
    .delete(`/codetracks/modules/delete/`, {
      data: {
        track_name: title,
        module_name: moduleToBeDeleted,
      }
    }).then((response) => {
      setModalIsOpen(false);
      setRefreshKey((oldKey) => oldKey + 1);
    })
    .catch((error) => {
      console.error(error);
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post(`/codetracks/modules/create/`, {
        track_name: title,
        module_name: newModuleValue,
      })
      .then((response) => {
        setRefreshKey((oldKey) => oldKey + 1);
        setTrackData(response.data);
        setNewModuleValue("");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    axios
      .get(`/track-content/${title}`)
      .then((response) => {
        setTrackData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [title, refreshKey]);

  return (
    <>
            <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={customStyles}
        contentLabel="Confirm Deletion"
      >
        <h2>Are you sure you want to delete this module?</h2>
        <div className="buttons" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <button style={{ backgroundColor: 'red', color: 'white', padding: '10px', borderRadius: '5px' }} onClick={handleModuleDelete}>Yes</button>
          <button style={{ backgroundColor: 'blue', color: 'white', padding: '10px', borderRadius: '5px' }} onClick={() => setModalIsOpen(false)}>No</button>
        </div>
      </Modal>
      <div className="w-full flex flex-col justify-center items-center">
        <div className="w-2/3 p-4 border-2 border-black text-left mt-4">
          <h2 className="text-lg mb-4">Add a new module</h2>
          <form onSubmit={handleSubmit}>
            <input
              className="border-2 border-gray-300 rounded p-2 mb-4 w-full"
              type="text"
              placeholder="Module name"
              value={newModuleValue}
              onChange={handleInputChange}
            />
            <button
              className="p-2 bg-blue-500 text-white rounded"
              type="submit"
            >
              Submit
            </button>
          </form>
        </div>
        <div className="w-2/3 text-left mt-8">
          <h2 className="text-lg">Track: {title}</h2>
          <div className="h-full border-black border-2">
            <h2 className="mt-2 ml-2">Content: </h2>
            <hr />
            <div className="ml-4">
              {trackData && trackData.map((module) => (
                  <div className="w-full mt-2 ">
                <div key={module.module_index}>
                  <div
                    className="flex flex-row w-full"
                  >
                    <p className="w-3/4 text-lg font-semibold active:bg-violet-700 hover:bg-sky-500"
                           onClick={() =>
                      navigateToProblemDetail(module.module_title, module)
                    }>
                      Module: {`${module.module_index} ${module.module_title}`}
                    </p>
                    <div className="w-1/4 flex flex-row-reverse">
                      <MdDelete color={'red'} size={"24px"} onClick={() => {
                        setModuleToBeDeleted(module.module_title);
                        setModalIsOpen(true);
                      }}/>
                      <FcExpand className="mx-4"/>
                    </div>
                  </div>
                </div>
                {module.subtopics && module.subtopics.map((subtopic) => (
                  <div
                    key={subtopic.subtopic_index}
                    style={{ marginLeft: "10px" }}
                  >
                    <p>
                      Subtopic:{" "}
                      {`${subtopic.subtopic_index} ${subtopic.subtopic_title}`}
                    </p>
                    {subtopic.problems && subtopic.problems.map((problem) => (
                      <p
                        key={problem.problem_index}
                        style={{ marginLeft: "20px" }}
                      >
                        Problem:{" "}
                        {`${problem.problem_index} ${problem.problem_title}`}
                      </p>
                    ))}
                  </div>
                ))}
              </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminTrack;
