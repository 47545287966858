import { Link } from "react-router-dom";
import AdminNavbar from "../../components/AdminNavbar.js";
function Dashboard() {
  return (
    <>
      <div className="h-screen flex">
        <AdminNavbar />
        <div className="w-6/7 sm:w-5/6 lg:w-7/8 flex justify-center">
          <h2 className="text-center">Home Management</h2>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
