import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function BuiltinTrack() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const ProblemSelect = function (problem_index, problem_title, refId) {
    console.log(problem_index, problem_title, refId);

    // Navigate to the new route when a problem is selected
    navigate(`/codetracks/builtin-methods/${refId}`, {
      state: { title: problem_title, problemRefId: refId },
    });
  };

  useEffect(() => {
    const trackName = "Beginner"; // Replace with the actual id

    axios
      .get(`/track-content/${trackName}`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div className="w-full h-screen flex justify-center">
      <div className="w-2/3 ">
        <div className="text-center">
          <h2>Track: Built-in Methods</h2>
          <hr />
          <div className="text-left">
            {data.map((module) => (
              <div key={module.module_index}>
                <p>{`${module.module_index} ${module.module_title}`}</p>
                {module.subtopics.map((subtopic) => (
                  <div
                    key={subtopic.subtopic_index}
                    style={{ marginLeft: "10px" }}
                  >
                    <p>{`${subtopic.subtopic_index} ${subtopic.subtopic_title}`}</p>
                    {subtopic.problems.map((problem) => (
                      <p
                        className="text-blue-800 hover:bg-sky-500 active:bg-violet-700"
                        onClick={() =>
                          ProblemSelect(
                            problem.problem_index,
                            problem.problem_title,
                            problem.refId
                          )
                        }
                        key={problem.problem_index}
                        style={{ marginLeft: "20px" }}
                      >{`${problem.problem_index} ${problem.problem_title}`}</p>
                    ))}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BuiltinTrack;
