import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Navbar from "./components/Navbar.js";
import CodeTracks from "./pages/codetracks/index.js";

export default function App() {
  return (
    <>
      <div className="h-screen">
        <Navbar />
        <body></body>
      </div>
    </>
  );
}
