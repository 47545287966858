import AdminModulesNavbar from "../../components/AdminModulesNavbar.js";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { RxReload } from 'react-icons/rx';

function Dashboard() {
  const location = useLocation();
  const module_title = location.state.module_title;
  const track_title = location.state.track_name;
  const problems = location.state.module;

  const [problemTitle, setProblemTitle] = useState("");
  const [problemDescription, setProblemDescription] = useState("");
  const [expectedInput, setExpectedInput] = useState("");
  const [expectedOutput, setExpectedOutput] = useState("");
  const [hints, setHints] = useState("");
  const [creatingNewProblem, setCreatingNewProblem] = useState(true);
  const [moduleData, setModuleData] = useState([]);
  const [showProblemRefId, setShowProblemRefId] = useState();
  const [showProblemInfo, setShowProblemInfo] = useState({});
  const [showProblemSubtopic, setShowProblemSubtopic] = useState();
  const [showProblemTitle, setShowProblemTitle] = useState();

  useEffect(() => {
    axios
      .get(`/track-module-content/${track_title}/${module_title}`)
      .then((response) => {
        setModuleData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [showProblemInfo]);

  const problemLoader = (refId, title, subtopic) => {
    setShowProblemRefId(refId);
    setShowProblemTitle(title);
    setShowProblemSubtopic(subtopic);

    axios
      .get(`/track-module-problem/${refId}`)
      .then((response) => {
        setShowProblemInfo(response.data);
        console.log(response.data);

        // set values based on the fetched problem info
        setProblemTitle(response.data.Title || "");
        setProblemDescription(response.data.Description || "");
        setExpectedInput(response.data.SolutionInput || "");
        setExpectedOutput(response.data.SolutionOutput || "");
        // As per your given object structure, there seems to be no direct mapping for hints
        setHints(""); // Clear hints for each new problem loaded as there's no specific hint data from backend
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleProblemUpdate = function() {
    let problemData = {
      Title: problemTitle,
      Description: problemDescription,
      SolutionInput: expectedInput,
      SolutionOutput: expectedOutput,
      Hints: hints,
    };

    if (creatingNewProblem) {
      // Add additional fields here
      problemData = {
        ...problemData,
        'code_track': track_title,
        'module_name': module_title,
          'subtopic_title': showProblemSubtopic
        // more fields here if needed
      };

      axios
        .post(`/track-module-problem/create`, problemData)
        .then((response) => {
          setShowProblemInfo(response.data);
          console.log(response.data);
          // After successful creation, set creatingNewProblem to false
          setCreatingNewProblem(false);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      axios
        .patch(`/track-module-problem/update/${showProblemRefId}`, problemData)
        .then((response) => {
          setShowProblemInfo(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
};

  const handleCreateProblem = function() {
    // Clear all the input fields
    setProblemTitle("");
    setProblemDescription("");
    setExpectedInput("");
    setExpectedOutput("");
    setHints("");

    // Set creatingNewProblem to true
    setCreatingNewProblem(true);
  };

  return (
    <>
      <div className="h-screen flex">
        <AdminModulesNavbar
          moduleTitle={module_title}
          moduleData={moduleData}
          problemLoader={problemLoader}
        />
        <div className="w-2/3 mx-auto">
          <div className={'w-full flex flex-row-reverse'}>
<button className={"bg-blue-200 rounded mr-4 h-8 px-4 mt-2"} onClick={handleCreateProblem}>Create +</button>
            <button className={"bg-red-200 rounded mr-4 h-8 px-4 mt-2"}>Resource</button>
            <button className={"bg-red-200 rounded mr-4 h-8 px-4 mt-2"}>Publish</button>
          </div>


          {showProblemInfo && (
            <div className="p-4 border rounded mb-4">
              <h3 className="text-xl font-bold mb-2">Modify Problem Details</h3>

              <div>
                <label className="text-lg font-semibold">Problem Title</label>
                <input
                  className="block w-full p-2 border rounded mt-2"
                  type="text"
                  value={problemTitle}
                  onChange={(e) => setProblemTitle(e.target.value)}
                />
              </div>

              <div>
                <label className="text-lg font-semibold">
                  Problem Description
                </label>
                <textarea
                  className="block w-full p-2 border rounded mt-2 h-20"
                  value={problemDescription}
                  onChange={(e) => setProblemDescription(e.target.value)}
                />
              </div>

              <div>
                <label className="text-lg font-semibold">Expected Input</label>
                <input
                  className="block w-full p-2 border rounded mt-2"
                  type="text"
                  value={expectedInput}
                  onChange={(e) => setExpectedInput(e.target.value)}
                />
              </div>

              <div>
                <label className="text-lg font-semibold">Expected Output</label>
                <input
                  className="block w-full p-2 border rounded mt-2"
                  type="text"
                  value={expectedOutput}
                  onChange={(e) => setExpectedOutput(e.target.value)}
                />
              </div>

              <div>
                <label className="text-lg font-semibold">Hints</label>
                <input
                  className="block w-full p-2 border rounded mt-2"
                  type="text"
                  value={hints}
                  onChange={(e) => setHints(e.target.value)}
                />
              </div>
            </div>
          )}
          <div className={"w-full h-20 flex justify-center"}>
            <button className={'mr-4 bg-blue-200 rounded-2xl px-4 h-9 flex flex-row spacing-3 justify-center text-center items-center text-sm'}>Refresh<RxReload className={"mx-2"}/></button>
<button className={'mr-4 bg-green-400 rounded px-12 h-9 text-sm'} onClick={() => handleProblemUpdate(showProblemRefId)}>Save</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
