import { useNavigate } from "react-router-dom";
import { IoChevronBackCircle } from "react-icons/io5";

function AdminModulesNavbar({ moduleTitle, moduleData, problemLoader}) {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };


  return (
    <>
      <div className="w-1/4 bg-gray-200 h-full">
        <nav className="">
          <IoChevronBackCircle
            className="mt-2 ml-2"
            onClick={goBack}
            size="32px"
            color="blue"
          />

          <h3 className="text-center text-md mb-4">Module: {moduleTitle}</h3>
          <ul className="">
            {Array.isArray(moduleData) ? (
              moduleData.map((subtopic) => (
                <li key={subtopic.SubtopicId}>
                  <h3 className="text-md font-semibold ml-2">{`Subtopic: ${subtopic.SubtopicId} ${subtopic.Title}`}</h3>
                  <ul>
                    {subtopic.Problems.map((problem) => (
                      <li key={problem.ProblemId} className="text-sm hover:bg-blue-200" onClick={() => problemLoader(problem.RefId, problem.Title, subtopic.Title)}><p className="ml-6">{`Problem: ${problem.ProblemIndex} ${problem.Title}`}</p></li>
                    ))}
                  </ul>
                </li>
            ))
            ) : (
              <p>No problems available</p>
            )}
          </ul>

        </nav>
      </div>
    </>
  );
}

export default AdminModulesNavbar;
