import Navbar from "../../components/AdminNavbar.js";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function DashboardCodetracks() {
  const [tracksNames, setTracksNames] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get("/get-tracks-names")
      .then((res) => {
        console.log(setTracksNames(res.data));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleTrackSelection = function (choice) {
    console.log(choice);

    navigate(`/dashboard/codetracks/choice/${choice}`, {
      state: { title: choice },
    });
  };
  return (
    <>
      <div className="h-screen flex">
        <Navbar />
        <div className="w-6/7 sm:w-5/6 lg:w-7/8 flex flex-col items-center pt-10">
          <h2 className="text-center">Admin CodeTracks</h2>
          <div className="w-2/3 flex flex-col space-y-4">
            <div className="p-4 rounded">
              <h3 className="text-lg">Add New Course Track</h3>
              <form className="space-y-2">
                <input
                  className="w-full p-2 border rounded"
                  type="text"
                  placeholder="Course Title"
                />
                <textarea
                  className="w-full p-2 border rounded"
                  rows="4"
                  placeholder="Course Description"
                ></textarea>
                <div className="flex justify-center">
                  <button className="w-1/2 p-2 rounded bg-blue-300">
                    Add Course
                  </button>
                </div>
              </form>
            </div>
            <div className="p-4 rounded">
              <h3 className="text-lg mb-4">View All Course Tracks</h3>
              <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                {Array.isArray(tracksNames) ? (
                  tracksNames.map((trackName, index) => (
                    <div
                      onClick={() => handleTrackSelection(trackName)}
                      key={index}
                      className="h-40 bg-blue-100 max-w-full rounded-lg border text-center pt-4"
                    >
                      <h2>{trackName}</h2>
                    </div>
                  ))
                ) : (
                  <p>No tracks available.</p>
                )}
                {/* Add more course tracks as div elements here */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardCodetracks;
