import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import CodeMirror from "@uiw/react-codemirror";
import { aura, auraInit } from "@uiw/codemirror-theme-aura";
import { python } from "@codemirror/lang-python";

const TrackProblemIDE = function () {
  const [problemData, setProblemData] = useState([]);
  const location = useLocation();
  const { title } = location.state;
  const { problemRefId } = location.state;
  const [codeSnippet, setCodeSnippet] = useState("");

  useEffect(() => {
    const trackName = "Beginner"; // Replace with the actual id

    axios
      .get(`/problem-detail/${problemRefId}`)
      .then((response) => {
        setProblemData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleCodeSubmit = function () {
    axios
      .post("/execute", { code: codeSnippet })

      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <div className="flex flex-row justify-center">
        <div className="w-2/3 mx-4">
          <h2 className="text-center text-lg">{title}</h2>
          <CodeMirror
            theme={aura}
            height="700px"
            extensions={[python({})]}
            value={codeSnippet}
            onChange={(value, viewUpdate) => {
              setCodeSnippet(value);
            }}
          />
          <div className="bg-white border-black border-2 h-20 flex flex-row-reverse items-center pe-6">
            <button
              className="bg-green-400 h-12 w-36 rounded"
              onClick={handleCodeSubmit}
            >
              Submit
            </button>
          </div>
        </div>
        <div className="w-1/3 flex flex-col h-[700px] justify-center space-y-4 mx-4">
          <div className="bg-blue-200 h-40">
            <p>Description</p>
            <p>{problemData.Description}</p>
          </div>
          <div className="bg-blue-200 h-20">
            <p>Input</p>
            <p>{JSON.stringify(problemData.SolutionInput)}</p>
          </div>
          <div className="bg-blue-200 h-20">
            <p>Expected output</p>
            <p>{problemData.SolutionOutput}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrackProblemIDE;
