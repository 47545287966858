import { Link } from "react-router-dom";

function AdminNavbar() {
  return (
    <>
      <div className="w-1/7 sm:w-1/6 lg:w-1/8 bg-gray-200 h-full">
        <nav className="">
          <h3 className="text-xl mb-4">Dashboard</h3>
          <ul className="space-y-4">
            <li>
              <Link to="/dashboard/codetracks">CodeTracks</Link>
            </li>
            <li>
              <Link to="/dashboard/codeblitz">CodeLabs</Link>
            </li>
            <li>
              <Link to="/dashboard/orders">CodeBlita</Link>
            </li>
            <li>
              <Link to="/dashboard/users">Users</Link>
            </li>
            <li>
              <Link to="/dashboard/docbase">DocBase</Link>
            </li>
            {/* Add more links as needed */}
          </ul>
        </nav>
      </div>
    </>
  );
}

export default AdminNavbar;
